<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技助力第48届ICPC亚洲区域赛（杭州）成功举办
              </p>
              <div class="fst-italic mb-2">2023年12月13日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >ICPC</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/65/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    12月9日-10日，由杭州师范大学、浙江大学承办的第48届ICPC国际大学生程序设计竞赛亚洲区域赛（杭州）圆满落幕。非凸科技作为此次赛事的主要支持之一，希望与各方共同努力，为国家计算机学科人才培养做出积极贡献。
                  </p>
                  <p>
                    本次比赛，共有296所院校及企业的482支队伍参赛，其中全国初高中11所26支赛队，共同呈现了一场精彩绝伦的科技盛宴。赛场上，参赛者们不仅展现了技能和智慧，还展现了团队精神和合作能力，以及当代学子的朝气蓬勃和无限潜力。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/65/02-榜单.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    经过紧张激烈的角逐，来自北京大学“摆烂人”队荣获冠军，清华大学的“一路向北”队获得亚军，杭州第二中学的“Remakers”队获得季军。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/65/03-颁奖.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为深耕金融科技领域的非凸科技，一直秉持发现、发展人才，坚持开放与创新，愿意与学术界共同培养优秀计算机程序设计人才。同时，也期待更多优秀学子加入非凸，共同探索数智交易。未来，非凸科技也将一如既往地回馈社会、支持教育事业。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/65/04-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    下一站，第48届ICPC亚洲区总决赛见！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News65",
};
</script>

<style></style>
